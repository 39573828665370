import { connect } from 'react-redux'
import acoes from '../../acoes'
import TesteDeTimeout from "./index"

const mapStateToProps = state => {

  return {
    testeDeTimeout: state.testeDeTimeout
  }
}

export default connect(mapStateToProps, acoes)(TesteDeTimeout)