import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'

import {
    ERRO_AO_LISTAR_TESTE_DE_TIMEOUT,
    LISTAR_TESTE_DE_TIMEOUT,
    LISTOU_TESTE_DE_TIMEOUT
} from "./tipos";

const listouTesteDeTimeout = teste => ({
    type: LISTOU_TESTE_DE_TIMEOUT,
    teste
})

const erroAoListarTesteDeTimeout = erro => ({
    type: ERRO_AO_LISTAR_TESTE_DE_TIMEOUT,
    erro
})

export const listarTesteDeTimeout = numero => async dispatch => {
    dispatch({ type: LISTAR_TESTE_DE_TIMEOUT })

    try {
        const resultado = await api.listarTesteDeTimeout(numero)

        dispatch(listouTesteDeTimeout(resultado.data.resultado))
        dispatch(adicionarNotificacoesDaApi(resultado))

        return resultado
    } catch (erro) {
        dispatch(erroAoListarTesteDeTimeout(erro))
        dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar testes de timeout.')))

        return false
    }
}