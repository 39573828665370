import React from 'react'
import Breadcrumb from '../breadcrumb'
import { Formulario as FormularioDoFormik, Input } from '../formik/formulario'

export default function Formulario(props) {
  const {
    salvar,
    dados
  } = props


  return (
    <div className='container'>
      <div className='main-content-form'>
        <div className='header-page-form'>
          <Breadcrumb />
        </div>
          <FormularioDoFormik
            reinicializar={false}
            valoresIniciais={{
              numero: 1
            }}
            acao={salvar}
          >
            <fieldset>
              <h2 className='form-title'>Teste de Timeout</h2>
              <r-grid columns-md='12'>
                <r-cell span={6} span-md={12} span-lg={6}>
                  <Input
                    type='text'
                    nome='numero'
                    tabIndex={1}
                    titulo='Número *'
                  />
                </r-cell>
              <div className='list-btn'>
                <button type='submit' className='button --primary'>Listar</button>
              </div>
              </r-grid>
              {dados &&
                  <div>
                    <h1>Retorno:</h1>
                    <p>{dados}</p>
                  </div>
              }
            </fieldset>
          </FormularioDoFormik>
      </div>
    </div>
  )
}