import React, { useEffect, useMemo, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'
import { Coluna, Tabela } from '../../../tabela'
import { converterObjetoParaArray } from '../../../../bibliotecas/conversao'
import { useUsuarioLogado } from '../../../seguranca/provedor-de-autenticacao'
import constantes from '../../../../configuracoes/constantes'
import { useFirestore } from '../../../../configuracoes/firestore/provedor'
import Filtro from './filtro'
import avatar from '../../../../design/img/no-thumb.png'
import { alterarUnderlinePorEspaco, colocarPrimeiraLetraMaiuscula } from '../../../../bibliotecas/texto'
import {
  diferencaEntreDatasNoFormatoSegundosENanosegundosReduzido,
  formatarDataEHoraParaFormatoLocal,
  formatarSegundosENanosegundosParaFormatoLocal
} from '../../../../bibliotecas/data'
import {
  CalendarCheck,
  Calendario,
  CalendarioComRelogio,
  Chat,
  Checkbox,
  ClipboardCheck,
  Clock,
  Diagram,
  ExclamationOctagon,
  FileText,
  Flag,
  FormChecked,
  Number,
  Phone,
  PlusCircle,
  Refer,
  Responsavel,
  Tag,
  User,
  XOctagon
} from '../../../../design/icones'

export default function Atividades(props) {
  const {
    identificadorDaTarefa,
    fotos = {},
    itens,
    recuperarFotoDoUsuarioPorIdentificador,
    registrarFirestoreDeAtividadesDaTarefa,
    responsaveis,
  } = props

  const { firestore } = useFirestore()
  const { usuario } = useUsuarioLogado()
  const empresa = usuario.token.empresa

  const [exibirCompleto, setExibirCompleto] = useState(false)

  useEffect(() => {
    return registrarFirestoreDeAtividadesDaTarefa(firestore, empresa, identificadorDaTarefa)
  }, [registrarFirestoreDeAtividadesDaTarefa, firestore, empresa, identificadorDaTarefa])

  useEffect(() => {
    const usuariosDoComentario = itens && responsaveis && responsaveis.filter(x => x.foto && itens.some(i => i.quem.identificador === x.identificadorPublico))
    usuariosDoComentario && usuariosDoComentario.forEach(item => {
      recuperarFotoDoUsuarioPorIdentificador(item.identificador, item.foto, constantes.tamanhoDaFoto.miniatura)
    })

  }, [itens, responsaveis, recuperarFotoDoUsuarioPorIdentificador])

  const atividades = useMemo(() => {
    const todas = converterObjetoParaArray(itens)

    if (exibirCompleto) return todas
    return todas.filter(t => eventoReduzido(t.evento))
  }, [itens, exibirCompleto])

  function eventoReduzido(evento) {
    if (evento.includes('TarefaCriada') || evento.includes('Status') ||
      evento.includes('Responsavel') || evento.includes('Urgencia')) return true

    return false
  }

  const tarefaCriada = atividades.filter(a => a.evento === 'TarefaCriada')[0]

  return (
    <>
      <Filtro alterarFiltro={() => setExibirCompleto(!exibirCompleto)} completo={exibirCompleto} />
      <Tabela
        className='table-items mt-2 mb-4'
        dados={atividades}
        exibirBotaoMais={false}
        chave='identificador'
      >
        <Coluna
          principal={true}
          nome='quem'
          className='col-lg-3'
          renderizar={item =>
            <div className='column-activity-log column-activity-log-who'>
              <img className='menu-account__pill__avatar' src={fotos[item.quem.identificador] || avatar} alt='Quem' />
              <RenderizarTexto texto={item.quem.nome} evento={item.evento} identificador={item.identificador} className='column-activity-log-who' />
            </div>
          }
        />
        <Coluna
          principal={true}
          nome='o que'
          className='col-lg-2'
          renderizar={item => <div className='column-activity-log'>{renderizarAcao(item.evento)}</div>}
        />
        <Coluna
          principal={true}
          nome='qual'
          className='col-lg-2'
          renderizar={item => <div className='column-activity-log'>{renderizarQual(item)}</div>}
        />
        <Coluna
          principal={true}
          nome=''
          className='col-lg-3'
          renderizar={item => <div className='column-activity-log'>{renderizarOQue(item)}</div>}
        />
        <Coluna
          principal={true}
          nome='quando'
          className='col-lg-2'
          renderizar={item => renderizarQuando(item, tarefaCriada)}
        />
      </Tabela>
    </>
  )
}

function renderizarQual(item) {
  const { evento, valor } = item

  const nomeDoCampoTexto = () => {
    const nome = valor.split(':')[0]
    return colocarPrimeiraLetraMaiuscula(alterarUnderlinePorEspaco(nome))
  }

  switch (evento) {
    case 'AgendamentoAdicionadoNaTarefa':
    case 'AgendamentoAlterado':
    case 'AgendamentoReagendado':
    case 'AgendamentoRemovidoDaTarefa':
      return <><i><CalendarCheck size='18' /></i>agendamento</>

    case 'AtendimentoAdicionadoNaTarefa':
    case 'AtendimentoRemovidoDaTarefa':
      return <><i><ClipboardCheck size='18' /></i>atendimento</>

    case 'CategoriaDaTarefaRemovida':
    case 'CategoriaDaTarefaDefinida':
      return <><i><Tag size='20' /></i>categoria</>

    case 'ComentarioAdicionadoNaTarefa':
    case 'ComentarioAlteradoNaTarefa':
    case 'ComentarioRemovidoDaTarefa':
      return <><i><Chat size='20' /></i>comentário</>

    case 'DataDaTarefaDefinida':
    case 'DataDaTarefaRemovida':
      return <><i><Calendario size='20' /></i>{nomeDoCampoTexto()}</>

    case 'DescricaoDaTarefaRemovida':
    case 'DescricaoDaTarefaDefinida':
      return <><i><FileText size='20' /></i>descrição</>

    case 'EncaminhamentoAdicionadoNaTarefa':
    case 'EncaminhamentoRemovidoDaTarefa':
      return <><i><Refer size='20' /></i>encaminhamento</>

    case 'EscalaAmbientalDeRiscoDeQuedaAdicionadaNaTarefa':
    case 'EscalaAmbientalDeRiscoDeQuedaAlterada':
    case 'EscalaAmbientalDeRiscoDeQuedaRemovidaDaTarefa':
      return <><i><FormChecked size='18' /></i>Escala Ambiental de Risco de Queda</>

    case 'ItemDaListaDeOpcoesMultiplasMarcado':
    case 'ItemDaListaDeOpcoesMultiplasDesmarcado':
      return <><i><Checkbox size='18' /></i>Item da lista</>

    case 'IvcfAdicionadoNaTarefa':
    case 'IvcfAlterado':
    case 'IvcfRemovidoDaTarefa':
      return <><i><FormChecked size='18' /></i>IVCF-20</>

    case 'MomentoDaTarefaDefinido':
    case 'MomentoDaTarefaRemovido':
      return <><i><Calendario size='20' /></i>{nomeDoCampoTexto()}</>

    case 'MotivoDeCancelamentoDaTarefaDefinido':
    case 'MotivoDeCancelamentoDaTarefaRemovido':
      return <><i><XOctagon size='20' /></i>cancelamento</>

    case 'NumeroInteiroDaTarefaDefinido':
    case 'NumeroInteiroDaTarefaRemovido':
      return <><i><Number size='23' /></i>{nomeDoCampoTexto()}</>

    case 'PacienteDaTarefaDefinido':
    case 'PacienteDaTarefaRemovido':
      return <><i><User size='25' /></i>paciente</>

    case 'ProfissionalResponsavelDaTarefaDefinido':
    case 'ProfissionalResponsavelDaTarefaRemovido':
    case 'SetorResponsavelDaTarefaDefinido':
    case 'SetorResponsavelDaTarefaRemovido':
      return <><i><Responsavel size='25' /></i>responsável</>

    case 'QuestionarioDoIdosoAdicionadoNaTarefa':
    case 'QuestionarioDoIdosoAlterado':
    case 'QuestionarioDoIdosoRemovidoDaTarefa':
      return <><i><FormChecked size='18' /></i>Questionário do Idoso</>

    case 'TarefaCriada':
      return <><i><PlusCircle size='20' /></i>criação</>

    case 'TarefaFinalizada':
    case 'TarefaCancelada':
      return <><i><Flag size='20' /></i>finalização</>

    case 'TarefaTrocouDePrazo':
      return <><i><CalendarioComRelogio size='20' /></i>prazo</>

    case 'TarefaTrocouDeStatus':
      return <><i><Diagram size='25' /></i>status</>

    case 'TelefoneDeContatoDaTarefaDefinido':
    case 'TelefoneDeContatoDaTarefaRemovido':
      return <><i><Phone size='20' /></i>telefone</>

    case 'TextoDaTarefaDefinido':
    case 'TextoDaTarefaRemovido':
    case 'TextoLongoDaTarefaDefinido':
    case 'TextoLongoDaTarefaRemovido':
      return <><i><FileText size='20' /></i>{nomeDoCampoTexto()}</>

    case 'UrgenciaDaTarefaRemovida':
    case 'UrgenciaDaTarefaDefinida':
      return <><i><ExclamationOctagon size='20' /></i>urgência</>

    default:
      return null
  }
}

function renderizarAcao(evento) {
  if (evento.includes('Adicionad')) return 'Adicionou'
  else if (evento.includes('Alterad')) return 'Alterou'
  else if (evento.includes('Definid')) return 'Definiu'
  else if (evento.includes('Desmarcad')) return 'Desmarcou'
  else if (evento.includes('Marca')) return 'Marcou'
  else if (evento.includes('Reagendad')) return 'Reagendou'
  else if (evento.includes('Removid')) return 'Removeu'
  else if (evento.includes('Trocou')) return 'Trocou'

  return null
}

function renderizarOQue(item) {
  const { evento, valor, identificador } = item

  const corDaUrgencia = codigo => {
    if (!codigo) return ''

    switch (codigo.toUpperCase()) {
      case 'VERMELHO':
        return '#BD3035'
      case 'LARANJA':
        return '#ED7A17'
      case 'AMARELO':
        return '#F9C72D'
      case 'VERDE':
        return '#70C968'
      default:
        return ''
    }
  }

  let style = {}
  let className = 'column-activity-log-what'
  let novoValor = valor

  if (evento === 'TarefaCriada' || evento === 'TarefaFinalizada' || evento === 'TarefaCancelada') return null
  if (evento === 'TarefaTrocouDePrazo') novoValor = calcularPrazoDaAtividade(valor)
  if (evento === 'ItemDaListaDeOpcoesMultiplasMarcado') novoValor = colocarPrimeiraLetraMaiuscula(alterarUnderlinePorEspaco(valor))

  const camposMultiplosDefinidos = ['TextoDaTarefaDefinido', 'TextoLongoDaTarefaDefinido', 'NumeroInteiroDaTarefaDefinido', 'MomentoDaTarefaDefinido', 'DataDaTarefaDefinida']
  if (camposMultiplosDefinidos.includes(evento)) novoValor = valor.split(/:(.*)/g)[1]

  if (evento === 'MomentoDaTarefaDefinido') novoValor = formatarDataEHoraParaFormatoLocal(novoValor)

  const camposMultiplosRemovidos = ['TextoDaTarefaRemovido', 'TextoLongoDaTarefaRemovido', 'NumeroInteiroDaTarefaRemovido', 'MomentoDaTarefaRemovido', 'DataDaTarefaRemovida']
  if (camposMultiplosRemovidos.includes(evento)) novoValor = ''

  if (evento.includes('Urgencia')) {
    style = { background: corDaUrgencia(novoValor) }
    className = 'table-items__bcolor w-auto sm'
  }

  return <RenderizarTexto texto={novoValor} evento={evento} identificador={identificador} className={className} style={style} />
}

function renderizarQuando(item, tarefaCriada) {
  const { evento, identificador, quando } = item

  let valor = evento === 'TarefaCriada' ?
    formatarSegundosENanosegundosParaFormatoLocal(quando) :
    'após ' + diferencaEntreDatasNoFormatoSegundosENanosegundosReduzido(tarefaCriada.quando, quando)

  return (
    <>
      <div
        data-tip
        data-for={`info_${evento}_${identificador}_${quando.seconds}`}
      >
        <div className='column-activity-log sm'>
          <i><Clock size='15' /></i>
          {valor}
        </div>
      </div>
      <ReactTooltip
        id={`info_${evento}_${identificador}_${quando.seconds}`}
        place='bottom'
        effect='solid'
        type='info'
        className='tooltip-atividades'
        key={`info_${evento}_${identificador}_${quando.seconds}`}
      >
        {formatarSegundosENanosegundosParaFormatoLocal(quando)}
      </ReactTooltip>
    </>
  )
}

function RenderizarTexto({ texto, evento, identificador, className, style }) {
  return (
    <>
      <div
        data-tip
        data-for={`info_${evento}_${identificador}_${className}`}
        className={className}
        style={style}
      >
        {texto}
      </div>
      <ReactTooltip
        id={`info_${evento}_${identificador}_${className}`}
        place='bottom'
        effect='solid'
        type='info'
        className='tooltip-atividades'
        key={`info_${evento}_${identificador}_${className}`}
      >
        <pre>{texto}</pre>
      </ReactTooltip>
    </>
  )
}

function calcularPrazoDaAtividade(valor) {
  const dataPrazo = moment(valor)

  const prazoEhIndefinido = moment(dataPrazo.format('YYYY-MM-DD')).isSame('2099-12-31')
  if (prazoEhIndefinido) return 'indefinido'

  return dataPrazo.format('DD/MM/YYYY, HH:mm')
}