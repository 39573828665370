import React, { useEffect, useState } from 'react'

export const BarraLateral = ({itens = [], aoSelecionar, selecionado = null}) => {
  const [itemSelecionado, setItemSelecionado] = useState(selecionado)

  useEffect(() => {
    if (aoSelecionar) {
      aoSelecionar(itemSelecionado)
    }
  }, [itemSelecionado, aoSelecionar])

  const aoClicar = item => {
    setItemSelecionado(item)
  }

  return (
    <div className='sidebar'>
      <div className='box-panel__sidebar'>
        <div className='box-panel__sidebar__items'>
          {
            itens.map((item, index) =>
              <ItemDaBarraLateral
                key={index}
                item={item}
                selecionado={itemSelecionado === item}
                aoClicar={aoClicar}/>
            )
          }
        </div>
      </div>
    </div>
  )
}

const ItemDaBarraLateral = ({item, selecionado, aoClicar}) => {
  return (
    <div
      className={`box-panel__sidebar__item --task ${selecionado ? 'is-selected' : ''}`}
      onClick={() => aoClicar(item)}
    >
      <p className='evolution-info__name'>{item}</p>
    </div>
  )
}