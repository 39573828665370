import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import RotaComAutenticacao from '../seguranca/rota-com-autenticacao'
import Formulario from "../teste-de-timeout/formulario";

export default function TesteDeTimeout(props) {
  const {
    match,
    listarTesteDeTimeout,
    testeDeTimeout
  } = props

  const listar = formulario => {
       listarTesteDeTimeout(formulario.numero)
  }

  return (
    <Switch>
        <RotaComAutenticacao
            exact
            path={`${match.path}/lista`}
            render={
                props => (
                        <Formulario
                            {...props}
                            salvar={listar}
                            dados={testeDeTimeout}
                        />
                )
            }
        />
      <Redirect exact from={match.path} to={`${match.path}/lista`} />
    </Switch>
  )
}