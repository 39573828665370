import api from '../api-major'

const adicionarCampoExternoDoPaciente = (identificador, campoExterno) => api.post(`/pacientes/${identificador}/campos-externos`, campoExterno)
const alterarCampoExternoDoPaciente = (identificador, campoExterno) => api.put(`/pacientes/${identificador}/campos-externos/${campoExterno.identificador}`, campoExterno)
const listarCamposExternosDoPaciente = identificador => api.get(`/pacientes/${identificador}/campos-externos?tamanhoDaPagina=1000&ordem=+nome`)
const removerCampoExternoDoPaciente = (identificadorDoPaciente, identificador) => api.delete(`/pacientes/${identificadorDoPaciente}/campos-externos/${identificador}`)
const recuperarCampoExternoDoPaciente = (identificadorDoPaciente, identificador) => api.get(`/pacientes/${identificadorDoPaciente}/campos-externos/${identificador}`)

export default {
  adicionarCampoExternoDoPaciente,
  alterarCampoExternoDoPaciente,
  listarCamposExternosDoPaciente,
  recuperarCampoExternoDoPaciente,
  removerCampoExternoDoPaciente,
}