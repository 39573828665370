import React, {useEffect, useState, useCallback, useRef} from 'react'
import { Foto } from './foto'
import { formatarDataParaFormatoLocal } from '../../bibliotecas/data'
import { useFormikContext } from "formik"
import moment from 'moment'
import chroma from 'chroma-js'

const maiorOuIgualASeis = intensidade => {
  return intensidade === 'seis' ||
      intensidade === 'sete' ||
      intensidade === 'oito' ||
      intensidade === 'nove' ||
      intensidade === 'dez'
}

const menorQueSeis = intensidade => {
  return intensidade === 'um' ||
      intensidade === 'dois' ||
      intensidade === 'tres' ||
      intensidade === 'quatro' ||
      intensidade === 'cinco'
}

const Resultado = ({ resultadosDasTriagens, sobrescrever }) => {
  const { setFieldValue, values, initialValues, touched, setTouched } = useFormikContext()
  const [resultado, setResultado] = useState('verde')
  const [resultadoCalculado, setResultadoCalculado] = useState('verde')

  const estahVazio = objeto => Object.keys(objeto).length === 0

  const calcularResultado = useCallback(() => {
    if (values.justificativa && values.justificativaInformada && values.resultado) {
      return values.resultado
    }

    let resultadoCalculado = 'verde'

    //respiração
    if (values.respiracao.dificuldadeParaRespirar ||
        values.respiracao.falaEntrecortadaOuRespiracaoAcelerada ||
        values.respiracao.coloracaoAzuladaNosLabiosOuExtremidades ||
        values.respiracao.podeEstarEngasgado) {
      return 'vermelho'
    }

    //dor no peito
    if (maiorOuIgualASeis(values.dorNoPeito.intensidade)) {
      return 'vermelho'
    }

    if (menorQueSeis(values.dorNoPeito.intensidade)) {
      resultadoCalculado = 'amarelo'
    }

    if (values.dorNoPeito.situacoesAssociadas.length > 0) {
      return 'vermelho'
    }

    //consciência
    if (values.alteracaoOuPerdaDaConsciencia.inconsciente
        || values.alteracaoOuPerdaDaConsciencia.dificuldadeParaRespirar) {
      return 'vermelho'
    }

    if (values.alteracaoOuPerdaDaConsciencia.situacoesAssociadas.length > 0) {
      resultadoCalculado = 'amarelo'
    }

    if (values.alteracaoOuPerdaDaConsciencia.confusoOuDesorientado === 'sim_e_nao_era_assim') {
      return 'vermelho'
    }

    if (values.alteracaoOuPerdaDaConsciencia.confusoOuDesorientado === 'sim_mas_jah_era_assim') {
      resultadoCalculado = 'amarelo'
    }

    if (values.alteracaoOuPerdaDaConsciencia.dificuldadeDeResponderPerguntas === 'sim_e_nao_era_assim') {
      return 'vermelho'
    }

    if (values.alteracaoOuPerdaDaConsciencia.dificuldadeDeResponderPerguntas === 'sim_mas_jah_era_assim') {
      resultadoCalculado = 'amarelo'
    }

    //queda
    if (values.queda.inconsciente) {
      return 'vermelho'
    }

    if (values.queda.dificuldadeParaRespirar) {
      return 'vermelho'
    }

    if (values.queda.situacoesAssociadas.length > 0) {
      resultadoCalculado = 'amarelo'
    }

    if (values.queda.confusoOuDesorientado === 'sim_e_nao_era_assim') {
      return 'vermelho'
    }

    if (values.queda.confusoOuDesorientado === 'sim_mas_jah_era_assim') {
      resultadoCalculado = 'amarelo'
    }

    if (values.queda.dificuldadeDeResponderPerguntas === 'sim_e_nao_era_assim') {
      return 'vermelho'
    }

    if (values.queda.dificuldadeDeResponderPerguntas === 'sim_mas_jah_era_assim') {
      resultadoCalculado = 'amarelo'
    }

    if (values.queda.incapacidadeDeSeMovimentar) {
      resultadoCalculado = 'amarelo'
    }

    if (values.queda.sangramentoLesaoAbertaHematomaOuEdema) {
      return 'vermelho'
    }

    if (maiorOuIgualASeis(values.queda.dor)) {
      return 'vermelho'
    }

    if (menorQueSeis(values.queda.dor)) {
      resultadoCalculado = 'amarelo'
    }

    //gastrointestinais
    if (values.alteracoesGastroIntestinais.diarreia === 'sim_com_sangue') {
      resultadoCalculado = 'amarelo'
    }

    if (values.alteracoesGastroIntestinais.vomito === 'sim_com_sangue') {
      resultadoCalculado = 'amarelo'
    }

    if (values.alteracoesGastroIntestinais.situacoesAssociadas.length > 0) {
      resultadoCalculado = 'amarelo'
    }

    if (maiorOuIgualASeis(values.alteracoesGastroIntestinais.dorAbdominal)) {
      return 'vermelho'
    }

    if (menorQueSeis(values.alteracoesGastroIntestinais.dorAbdominal)) {
      resultadoCalculado = 'amarelo'
    }

    //urinário
    if (values.sintomasUrinarios.semUrinarHaMaisDeOitoHoras) {
      return 'vermelho'
    }

    if (maiorOuIgualASeis(values.sintomasUrinarios.dorAbdominal)) {
      return 'vermelho'
    }

    if (menorQueSeis(values.sintomasUrinarios.dorAbdominal)) {
      resultadoCalculado = 'amarelo'
    }

    const situacoesQuePontuam = values.sintomasUrinarios.situacoesAssociadas
        .filter(x => x !== 'dor_para_urinar' && x !== 'vontade_de_urinar_varias_vezes')

    if (situacoesQuePontuam.length > 0) {
      resultadoCalculado = 'amarelo'
    }

    //muscoesquelética
    if (values.dorMusculoesqueletica.incapacitante) {
      return 'vermelho'
    }

    if (maiorOuIgualASeis(values.dorMusculoesqueletica.intensidade)) {
      return 'vermelho'
    }

    if (menorQueSeis(values.dorMusculoesqueletica.intensidade)) {
      resultadoCalculado = 'amarelo'
    }

    if (values.dorMusculoesqueletica.sintomasAssociados.length > 0) {
      resultadoCalculado = 'amarelo'
    }

    //hemorragia
    if (values.hemorragia.sinaisDeHemorragia) {
      resultadoCalculado = 'amarelo'
    }

    if (values.hemorragia.respostaAPressao === 'continua') {
      return 'vermelho'
    }

    if (values.hemorragia.usaAnticoagulantes) {
      resultadoCalculado = 'amarelo'
    }

    return resultadoCalculado
  }, [values])

  useEffect(() => {
    const novoResultadoCalculado = calcularResultado()
    setResultadoCalculado(novoResultadoCalculado)

    if (!values.justificativaInformada || (estahVazio(touched) && values.justificativaInformada && values.identificador && resultado !== initialValues.resultado)) {
      setResultado(novoResultadoCalculado)
    }
  }, [calcularResultado, values, resultado, initialValues, touched])

  const descricaoDoResultado = resultadosDasTriagens.find(x => x.codigo === resultado)?.nome || ''

  const corDaUrgencia = codigo => {
    if (!codigo) return ''

    switch (codigo) {
      case 'vermelho':
        return '#BD3035'
      case 'amarelo':
        return '#F9C72D'
      case 'verde':
        return '#70C968'
      default:
        return ''
    }
  }

  const codigoDaCor = corDaUrgencia(resultado)

  const color = codigoDaCor && chroma(codigoDaCor)
  const corDaFonte = color && chroma.contrast(color, 'white') > 2 ? 'white' : 'black'

  const dadosModificados = values.justificativa && values.resultado && resultado !== values.resultado

  const sair = () => {
    setFieldValue('exibir', false)
  }

  const alterar = () => {
    setFieldValue('justificativaInformada', true)
    setFieldValue('exibir', false)
    setResultado(values.resultado)
  }

  const removerResultadoFixo = () => {
    sobrescrever()
    setFieldValue('exibir', false)
    setResultado(resultadoCalculado)
  }

  const exibirJustificativa = () => {
    setFieldValue('exibir', true)
  }

  const inputRef = useRef(null)

  useEffect(() => {
    if (inputRef.current) {
      const length = inputRef.current.value.length
      inputRef.current.setSelectionRange(length, length)
    }
  }, [values.justificativa])

  const escolherCor = cor => {
    setFieldValue('resultado', cor)
    setTouched({ resultado: true })
  }

  return (
    <div>
      <div
        className='resultado'
        style={{ backgroundColor: color }}
      >
        {values.exibir &&
          <div className='tooltip__drop custom-scrollbar'>
            <div className='tooltip__drop__body'>
              <div className='tooltip__drop__title'>
                {resultado ? 'Alterar' : 'Informar'} resultado
                <button
                  className='tooltip__drop__bt-close'
                  type='button'
                  onClick={sair}>
                  <i className='icon-task icon-close'></i>
                </button>
              </div>
              <div className='tooltip__drop__description'>
                <div className='conteudo-tooltip'>
                  <div className='form-group'>
                    <label className='form-label'>Classificação</label>
                    <div className='week-options'>
                      <div
                        key='verde'
                        className={`week-options__item item-verde ${values.resultado === 'verde' ? 'is-selected' : ''}`}
                        onClick={() => escolherCor('verde')}
                        style={values.resultado === 'verde' ? { backgroundColor: '#70C968', color: 'white' } : null}
                      >
                        Verde
                      </div>
                      <div
                        key='amarelo'
                        className={`week-options__item item-amarelo ${values.resultado === 'amarelo' ? 'is-selected' : ''}`}
                        style={values.resultado === 'amarelo' ? { backgroundColor: '#F9C72D', color: 'black' } : null}
                        onClick={() => escolherCor('amarelo')}
                      >
                        Amarelo
                      </div>
                      <div
                        key='vermelho'
                        className={`week-options__item item-vermelho ${values.resultado === 'vermelho' ? 'is-selected' : ''}`}
                        onClick={() => escolherCor('vermelho')}
                        style={values.resultado === 'vermelho' ? { backgroundColor: '#BD3035', color: 'white' } : null}
                      >
                        Vermelho
                      </div>
                    </div>
                  </div>
                  <div className='form-group'>
                    <label className='form-label'>Justificativa do Resultado</label>
                    <textarea
                        name='justificativa'
                        value={values.justificativa}
                        autoFocus
                        ref={inputRef}
                        onChange={e => setFieldValue('justificativa', e.target.value)}
                    />
                  </div>
                </div>
                <div className='acoes-tooltip'>
                <button
                      type='button'
                      onClick={removerResultadoFixo}
                      className='button --light'
                  >
                    Remover Resultado Fixo
                  </button>
                  <button
                    type='button'
                    onClick={alterar}
                    className='button --primary'
                    disabled={!dadosModificados}
                  >
                    Salvar
                  </button>
                </div>
              </div>
            </div>
          </div>
        }
        <span style={{ color: corDaFonte }} className='descricao-da-cor'>{descricaoDoResultado}</span>
      </div>
      <button
        type='button'
        className='button --light btn-alterar-resultado-triagem'
        onClick={() => exibirJustificativa()}
      >
        Alterar Resultado
      </button>
    </div>
  )
}

const Idade = ({ data }) => {
  if (!data) {
    return null
  }

  const nascimento = moment(data)

  if (!nascimento.isValid()) {
    return ''
  }

  const agora = moment()
  const duracao = moment.duration(agora.diff(nascimento))
  const anos = Math.floor(duracao.asYears())
  const meses = Math.floor(duracao.asMonths() % 12)

  return (
      <div>
        <div>{`${anos} anos ${meses > 0 ? 'e ' + meses + ' meses' : ''}`}</div>
      </div>
  )
}

const Programa = ({ programas = [] }) => {
  if (programas.length === 0) {
    return <div className='sem-programa'>PACIENTE NÃO POSSUI PROGRAMA</div>
  }

  return <div className='programa'>{programas.join(', ')}</div>
}

const Sexo = ({ sexo }) => {
  if (!sexo) {
    return null
  }

  return <div>{sexo}</div>
}

const Convenio = ({ convenios = [] }) => {
  if (convenios.length === 0) {
    return <div className='sem-convenio'>PACIENTE NÃO POSSUI CONVÊNIO</div>
  }

  return <div className='convenio'>{convenios.join(', ')}</div>
}

export default function Cabecalho({ paciente, resultadosDasTriagens = [], justificativa, }) {
  const { values, setFieldValue } = useFormikContext()

  const sobrescreverResultado = () => {
    setFieldValue('resultado', null)
    setFieldValue('justificativaInformada', false)
    setFieldValue('justificativa', '')
  }

  return (
      <>
        <div className='cabecalho-triagem w-100'>
          <Foto foto={paciente.foto}/>
          <div className='identificacao'>
            <div className='nome'>{paciente.nome}</div>
            <div>
              <div className='sexo-e-idade'>
                <Sexo sexo={paciente.sexo}/>
                <div> -</div>
                <Idade data={paciente.dataDeNascimento}/>
              </div>
              <div className='data-de-nascimento'>Data de
                nascimento: {formatarDataParaFormatoLocal(paciente.dataDeNascimento)}</div>
              <div className='data-de-nascimento'>Mãe: {paciente.nomeDaMae}</div>
            </div>
          </div>
          <div className='dados-de-atendimento'>
            <Convenio convenios={[paciente.convenio]}/>
            <Programa programas={paciente.programas}/>
            {paciente.medicoDeReferencia && <div className='medico'>MR: {paciente.medicoDeReferencia} </div>}
            {paciente.enfermeiroDeReferencia && <div className='enfermeiro'>ER: {paciente.enfermeiroDeReferencia} </div>}
          </div>
          <Resultado
              key={justificativa}
              resultadosDasTriagens={resultadosDasTriagens}
              sobrescrever={sobrescreverResultado}
              justificativaPadrao={justificativa}
          />
        </div>
        {values.justificativa && values.justificativaInformada &&
            <div>
              <div className='form-choice-alerta'>
                <div className='form-choice-alerta__item'>
                  <i className='icon icon-attention-circled'></i>
                </div>
                <div className='form-choice-alerta__item'>
                  <h2 className='alerta-de-justificativa-preenchida'>
                    O resultado da triagem foi alterado e uma justificativa foi informada. Portanto, mudanças no questionário não afetarão o resultado.
                  </h2>
                  <button type='button' className='button-remover-justificativa-triagem' onClick={sobrescreverResultado}>Remover resultado fixo</button>
                </div>
              </div>
            </div>
        }
      </>
  )
}