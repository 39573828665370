import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { BarraLateral } from './barra-lateral'
import { Secao, Secoes } from './secoes'
import { ADICIONAR_TRIAGEM, ALTERAR_TRIAGEM } from '../../acoes/tipos'
import { EscalaTriagem, Formulario, Input, MultiplaSelecaoTriagem, OpcoesTriagem } from "../formik/formulario"
import Spinner from '../spinner'
import Cabecalho from './cabecalho'


export default function Triagem(props) {
  const {
    paciente,
    listarIntensidadesDasDoresDaTriagem,
    listarCondicoesRelacionadasAosEstadosHabituais,
    listarSituacoesAssociadasAAlteracaoOuPerdaDaConsciencia,
    listarSituacoesAssociadasAAlteracoesGastrointestinais,
    listarSituacoesAssociadasADorNoPeito,
    listarSituacoesAssociadasAQueda,
    listarSituacoesAssociadasASintomasUrinarios,
    listarSintomasAssociadosAoLocalDaDorMusculoesqueletica,
    listarRespostasAPressaoNoLocalDaHemorragia,
    listarRespostasValidasSobreDiarreia,
    listarRespostasValidasSobreVomito,
    listarResultadosDasTriagens,
    resultadosDasTriagens,
    situacoesAssociadasADorNoPeito = [],
    situacoesAssociadasAAlteracaoOuPerdaDaConsciencia = [],
    condicoesRelacionadasAosEstadosHabituais = [],
    situacoesAssociadasAQueda = [],
    respostasValidasSobreDiarreia = [],
    respostasValidasSobreVomito = [],
    respostasAPressaoNoLocalDaHemorragia = [],
    situacoesAssociadasAAlteracoesGastrointestinais = [],
    situacoesAssociadasASintomasUrinarios = [],
    sintomasAssociadasAoLocalDaDorMusculoesqueletica = [],
    adicionarTriagem,
    alterarTriagem,
    cancelar,
    triagem,
  } = props

  const triagemPadrao= {
    paciente: paciente.identificador,
    respiracao: {
      dificuldadeParaRespirar: false,
      falaEntrecortadaOuRespiracaoAcelerada: false,
      coloracaoAzuladaNosLabiosOuExtremidades: false,
      podeEstarEngasgado: false,
      observacoes: ''
    },
    dorNoPeito: {
      sentindoDor: false,
      intensidade: null,
      situacoesAssociadas: [],
      observacoes: ''
    },
    alteracaoOuPerdaDaConsciencia: {
      inconsciente: false,
      dificuldadeParaRespirar: false,
      situacoesAssociadas: [],
      confusoOuDesorientado: 'nao',
      dificuldadeDeResponderPerguntas: 'nao',
      observacoes: ''
    },
    queda: {
      aconteceuHaMenosDeVinteEQuatroHoras: false,
      inconsciente: false,
      dificuldadeParaRespirar: false,
      situacoesAssociadas: [],
      confusoOuDesorientado: 'nao',
      dificuldadeDeResponderPerguntas: 'nao',
      incapacidadeDeSeMovimentar: false,
      sangramentoLesaoAbertaHematomaOuEdema: false,
      presencaDeDor: false,
      dor: null,
      observacoes: ''
    },
    alteracoesGastroIntestinais: {
      diarreia: 'nao',
      vomito: 'nao',
      situacoesAssociadas: [],
      presencaDeDorAbdominal: false,
      dorAbdominal: null,
      observacoes: '',
    },
    dorMusculoesqueletica: {
      incapacitante: false,
      presencaDeDor: false,
      intensidade: null,
      iniciouHaMenosDeVinteEQuatroHoras: false,
      sintomasAssociados: [],
      observacoes: '',
    },
    sintomasUrinarios: {
      semUrinarHaMaisDeOitoHoras: false,
      presencaDeDorAbdominal: false,
      dorAbdominal: null,
      situacoesAssociadas: [],
      observacoes: '',
    },
    hemorragia: {
      sinaisDeHemorragia: false,
      respostaAPressao: null,
      usaAnticoagulantes: false,
      observacoes: '',
    },
    resultado: null,
    justificativa: '',
  }

  const valoresIniciais = () => {
    if (triagem?.identificador)  {
      return {
        ...triagem,
        paciente: paciente.identificador,
        dorNoPeito: {
          ...triagem.dorNoPeito,
          sentindoDor: triagem.dorNoPeito.intensidade !== null
        },
        queda: {
          ...triagem.queda,
          presencaDeDor: triagem.queda.dor !== null
        },
        alteracoesGastroIntestinais: {
          ...triagem.alteracoesGastroIntestinais,
          presencaDeDorAbdominal: triagem.alteracoesGastroIntestinais.dorAbdominal !== null
        },
        dorMusculoesqueletica: {
          ...triagem.dorMusculoesqueletica,
          presencaDeDor: triagem.dorMusculoesqueletica.intensidade !== null
        },
        resultado: triagem.resultado,
        justificativaInformada: !!triagem.justificativa,
      }
    }

    return triagemPadrao
  }

  const [secaoSelecionada, setSecaoSelecionada] = useState('Respiração')

  useEffect(() => {
    listarResultadosDasTriagens()
    listarIntensidadesDasDoresDaTriagem()
    listarCondicoesRelacionadasAosEstadosHabituais()
    listarSituacoesAssociadasAAlteracaoOuPerdaDaConsciencia()
    listarSituacoesAssociadasAAlteracoesGastrointestinais()
    listarSituacoesAssociadasADorNoPeito()
    listarSituacoesAssociadasAQueda()
    listarSituacoesAssociadasASintomasUrinarios()
    listarSintomasAssociadosAoLocalDaDorMusculoesqueletica()
    listarRespostasAPressaoNoLocalDaHemorragia()
    listarRespostasValidasSobreDiarreia()
    listarRespostasValidasSobreVomito()
  }, [
    listarIntensidadesDasDoresDaTriagem,
    listarCondicoesRelacionadasAosEstadosHabituais,
    listarSituacoesAssociadasAAlteracaoOuPerdaDaConsciencia,
    listarSituacoesAssociadasAAlteracoesGastrointestinais,
    listarSituacoesAssociadasADorNoPeito,
    listarSituacoesAssociadasAQueda,
    listarSituacoesAssociadasASintomasUrinarios,
    listarSintomasAssociadosAoLocalDaDorMusculoesqueletica,
    listarRespostasAPressaoNoLocalDaHemorragia,
    listarRespostasValidasSobreDiarreia,
    listarRespostasValidasSobreVomito,
    listarResultadosDasTriagens])

  const gravar = async formulario => {
    if (!formulario.identificador) {
      const adicionou = await adicionarTriagem({...formulario, identificador: uuidv4()} )

      if (adicionou) {
        cancelar()
      }
    } else {
      const alterou = await alterarTriagem(formulario)

      if (alterou) {
        cancelar()
      }
    }
  }

  const secoes = ['Respiração', 'Dor no Peito', 'Alteração ou Perda da Consciência', 'Queda', 'Alterações Gastrointestinais', 'Sintomas Urinários', 'Dor Musculoesquelética', 'Hemorragia']

  const indexDaSecaoSelecionada = secoes.indexOf(secaoSelecionada)

  const aoResponderPerguntaIntensidadeDorNoPeito = (setFieldValue, valor) => {
    if (!valor) {
      setFieldValue("dorNoPeito.intensidade", null)
      setFieldValue("dorNoPeito.situacoesAssociadas", [])
    }
  }

  const aoResponderPerguntaPresencaDeDorQueda = (setFieldValue, valor) => {
    if (!valor) {
      setFieldValue("queda.dor", null)
    }
  }

  const aoResponderPerguntaDorAbdominalAlteracoesGastrointestinais = (setFieldValue, valor) => {
    if (!valor) {
      setFieldValue("alteracoesGastroIntestinais.dorAbdominal", null)
    }
  }

  const aoResponderPerguntaPresencaDeDorMuscoloesqueletica = (setFieldValue, valor) => {
    if (!valor) {
      setFieldValue("dorMusculoesqueletica.intensidade", null)
      setFieldValue("dorMusculoesqueletica.incapacitante", false)
    }
  }

  const aoResponderPerguntaPresencaDeDorAbdominalSintomasUrinarios = (setFieldValue, valor) => {
    if (!valor) {
      setFieldValue("sintomasUrinarios.dorAbdominal", null)
    }
  }

  const aoResponderPerguntaSinaisDeHemorragia = (setFieldValue, valor) => {
    if (!valor) {
      setFieldValue("hemorragia.respostaAPressao", null)
    }
  }

  return (
    <>
      <Spinner operacoes={[ADICIONAR_TRIAGEM, ALTERAR_TRIAGEM]}>
        {({ processando }) => (
            <Formulario
              reinicializar={false}
              valoresIniciais={valoresIniciais()}
              acao={gravar}
            >
              {({ values }) => (
              <div className={`triagem form ${processando && 'is-loading'}`}>
                <Cabecalho
                    paciente={paciente}
                    resultadosDasTriagens={resultadosDasTriagens}
                    justificativa={values.justificativa}
                />

                <div className='conteudo-triagem tarefas'>
                  <BarraLateral
                      itens={secoes}
                      selecionado={secaoSelecionada}
                      aoSelecionar={item => setSecaoSelecionada(item)}
                  />
                  <Secoes
                      className='perguntas'
                      abaSelecionada={indexDaSecaoSelecionada}
                      questionario={values}
                  >
                    <Secao chave='respiracao'>
                      <OpcoesTriagem
                          titulo='Está com dificuldade para respirar?'
                          nome='respiracao.dificuldadeParaRespirar'
                          opcoes={[{nome: 'Sim', codigo: true}, {nome: 'Não', codigo: false}]}
                          selecaoUnica={true}
                      />
                      <OpcoesTriagem
                          titulo="A fala está entrecortada ou a respiração acelerada?"
                          nome="respiracao.falaEntrecortadaOuRespiracaoAcelerada"
                          opcoes={[{nome: 'Sim', codigo: true}, {nome: 'Não', codigo: false}]}
                          selecaoUnica={true}
                      />
                      <OpcoesTriagem
                          titulo="Há coloração azulada nos lábios ou extremidades?"
                          nome="respiracao.coloracaoAzuladaNosLabiosOuExtremidades"
                          opcoes={[{nome: 'Sim', codigo: true}, {nome: 'Não', codigo: false}]}
                          selecaoUnica={true}
                      />
                      <OpcoesTriagem
                          titulo="Pode estar engasgado (sensação de sufocação, mãos no pescoço, salivação)?"
                          nome="respiracao.podeEstarEngasgado"
                          opcoes={[{nome: 'Sim', codigo: true}, {nome: 'Não', codigo: false}]}
                          selecaoUnica={true}
                      />
                      <Input
                          as='textarea'
                          nome='respiracao.observacoes'
                          titulo='Observações'
                          classname='pergunta-container'
                          classNameTitulo='texto-pergunta'
                      />
                    </Secao>
                    <Secao chave='dorNoPeito'>
                      <OpcoesTriagem
                          titulo="Está sentindo dor no peito?"
                          nome="dorNoPeito.sentindoDor"
                          opcoes={[{nome: 'Sim', codigo: true}, {nome: 'Não', codigo: false}]}
                          selecaoUnica={true}
                          aoResponder={aoResponderPerguntaIntensidadeDorNoPeito}
                      />
                        <EscalaTriagem
                            nome="dorNoPeito.intensidade"
                            desabilitado={!values.dorNoPeito.sentindoDor}
                            escalas={[
                              { codigo: 'um', nome: 1 },
                              { codigo: 'dois', nome: 2 },
                              { codigo: 'tres', nome: 3 },
                              { codigo: 'quatro', nome: 4 },
                              { codigo: 'cinco', nome: 5 },
                              { codigo: 'seis', nome: 6 },
                              { codigo: 'sete', nome: 7 },
                              { codigo: 'oito', nome: 8 },
                              { codigo: 'nove', nome: 9 },
                              { codigo: 'dez', nome: 10 }
                            ]}
                        />
                      <MultiplaSelecaoTriagem
                          className="pergunta"
                          nome="dorNoPeito.situacoesAssociadas"
                          opcoes={situacoesAssociadasADorNoPeito}
                          desabilitado={!values.dorNoPeito.intensidade}
                          pergunta="Teve alguma das seguintes situações associadas?"
                      />
                      <Input
                          as='textarea'
                          nome='dorNoPeito.observacoes'
                          titulo='Observações'
                          classname='pergunta-container'
                          classNameTitulo='texto-pergunta'
                      />
                    </Secao>
                    <Secao chave='alteracaoOuPerdaDaConsciencia'>
                      <OpcoesTriagem
                          titulo="O paciente está inconsciente?"
                          nome="alteracaoOuPerdaDaConsciencia.inconsciente"
                          opcoes={[{nome: 'Sim', codigo: true}, {nome: 'Não', codigo: false}]}
                          selecaoUnica={true}
                      />
                      <OpcoesTriagem
                          titulo="Está com dificuldade ou sem respirar?"
                          nome="alteracaoOuPerdaDaConsciencia.dificuldadeParaRespirar"
                          opcoes={[{nome: 'Sim', codigo: true}, {nome: 'Não', codigo: false}]}
                          selecaoUnica={true}
                      />
                      <MultiplaSelecaoTriagem
                          className="pergunta"
                          nome="alteracaoOuPerdaDaConsciencia.situacoesAssociadas"
                          opcoes={situacoesAssociadasAAlteracaoOuPerdaDaConsciencia}
                          pergunta="Teve alguma das seguintes situações, recentemente?"
                      />
                      <OpcoesTriagem
                          titulo="Em relação ao estado habitual, nota que está confuso ou desorientado?"
                          nome="alteracaoOuPerdaDaConsciencia.confusoOuDesorientado"
                          opcoes={condicoesRelacionadasAosEstadosHabituais}
                          selecaoUnica={true}
                      />
                      <OpcoesTriagem
                          titulo="Tem dificuldade em responder às perguntas?"
                          nome="alteracaoOuPerdaDaConsciencia.dificuldadeDeResponderPerguntas"
                          opcoes={condicoesRelacionadasAosEstadosHabituais}
                          selecaoUnica={true}
                      />
                      <Input
                          as='textarea'
                          nome='alteracaoOuPerdaDaConsciencia.observacoes'
                          titulo='Observações'
                          classname='pergunta-container'
                          classNameTitulo='texto-pergunta'
                      />
                    </Secao>
                    <Secao chave='queda'>
                      <OpcoesTriagem
                          titulo="Aconteceu há menos de 24h?"
                          nome="queda.aconteceuHaMenosDeVinteEQuatroHoras"
                          opcoes={[{nome: 'Sim', codigo: true}, {nome: 'Não', codigo: false}]}
                          selecaoUnica={true}
                      />
                      <OpcoesTriagem
                          titulo="Está inconsciente?"
                          nome="queda.inconsciente"
                          opcoes={[{nome: 'Sim', codigo: true}, {nome: 'Não', codigo: false}]}
                          selecaoUnica={true}
                      />
                      <OpcoesTriagem
                          titulo="Está com dificuldade ou sem respirar?"
                          nome="queda.dificuldadeParaRespirar"
                          opcoes={[{nome: 'Sim', codigo: true}, {nome: 'Não', codigo: false}]}
                          selecaoUnica={true}
                      />
                      <MultiplaSelecaoTriagem
                          className="pergunta"
                          nome="queda.situacoesAssociadas"
                          opcoes={situacoesAssociadasAQueda}
                          pergunta="Teve alguma das seguintes situações associadas?"
                      />
                      <OpcoesTriagem
                          titulo="Em relação ao estado habitual, nota que está confuso ou desorientado?"
                          nome="queda.confusoOuDesorientado"
                          opcoes={condicoesRelacionadasAosEstadosHabituais}
                          selecaoUnica={true}
                      />
                      <OpcoesTriagem
                          titulo="Tem dificuldade em responder às perguntas?"
                          nome="queda.dificuldadeDeResponderPerguntas"
                          opcoes={condicoesRelacionadasAosEstadosHabituais}
                          selecaoUnica={true}
                      />
                      <OpcoesTriagem
                          titulo="Incapacidade para se movimentar normalmente após a queda?"
                          nome="queda.incapacidadeDeSeMovimentar"
                          opcoes={[{nome: 'Sim', codigo: true}, {nome: 'Não', codigo: false}]}
                          selecaoUnica={true}
                      />
                      <OpcoesTriagem
                          titulo="Apresenta sangramento, lesão aberta, hematoma ou edema após a queda?"
                          nome="queda.sangramentoLesaoAbertaHematomaOuEdema"
                          opcoes={[{nome: 'Sim', codigo: true}, {nome: 'Não', codigo: false}]}
                          selecaoUnica={true}
                      />
                      <OpcoesTriagem
                          titulo="Presença de dor?"
                          nome="queda.presencaDeDor"
                          opcoes={[{nome: 'Sim', codigo: true}, {nome: 'Não', codigo: false}]}
                          aoResponder={aoResponderPerguntaPresencaDeDorQueda}
                          selecaoUnica={true}
                      />
                      <EscalaTriagem
                          nome="queda.dor"
                          desabilitado={!values.queda.presencaDeDor}
                          escalas={[
                            { codigo: 'um', nome: 1 },
                            { codigo: 'dois', nome: 2 },
                            { codigo: 'tres', nome: 3 },
                            { codigo: 'quatro', nome: 4 },
                            { codigo: 'cinco', nome: 5 },
                            { codigo: 'seis', nome: 6 },
                            { codigo: 'sete', nome: 7 },
                            { codigo: 'oito', nome: 8 },
                            { codigo: 'nove', nome: 9 },
                            { codigo: 'dez', nome: 10 }
                          ]}
                      />
                      <Input
                          as='textarea'
                          nome='queda.observacoes'
                          titulo='Observações'
                          classname='pergunta-container'
                          classNameTitulo='texto-pergunta'
                      />
                    </Secao>
                    <Secao chave='alteracoesGastroIntestinais'>
                      <OpcoesTriagem
                          titulo="O paciente está com diarreia?"
                          nome="alteracoesGastroIntestinais.diarreia"
                          opcoes={respostasValidasSobreDiarreia}
                          selecaoUnica={true}
                      />
                      <OpcoesTriagem
                          titulo="O paciente está com vômitos?"
                          nome="alteracoesGastroIntestinais.vomito"
                          opcoes={respostasValidasSobreVomito}
                          selecaoUnica={true}
                      />
                      <MultiplaSelecaoTriagem
                          className="pergunta"
                          nome="alteracoesGastroIntestinais.situacoesAssociadas"
                          opcoes={situacoesAssociadasAAlteracoesGastrointestinais}
                          pergunta="Teve alguma das seguintes situações associadas?"
                      />
                      <OpcoesTriagem
                          titulo="O paciente apresenta dor abdominal?"
                          nome="alteracoesGastroIntestinais.presencaDeDorAbdominal"
                          opcoes={[{nome: 'Sim', codigo: true}, {nome: 'Não', codigo: false}]}
                          aoResponder={aoResponderPerguntaDorAbdominalAlteracoesGastrointestinais}
                          selecaoUnica={true}
                      />
                      <EscalaTriagem
                          nome="alteracoesGastroIntestinais.dorAbdominal"
                          desabilitado={!values.alteracoesGastroIntestinais.presencaDeDorAbdominal}
                          escalas={[
                            { codigo: 'um', nome: 1 },
                            { codigo: 'dois', nome: 2 },
                            { codigo: 'tres', nome: 3 },
                            { codigo: 'quatro', nome: 4 },
                            { codigo: 'cinco', nome: 5 },
                            { codigo: 'seis', nome: 6 },
                            { codigo: 'sete', nome: 7 },
                            { codigo: 'oito', nome: 8 },
                            { codigo: 'nove', nome: 9 },
                            { codigo: 'dez', nome: 10 }
                          ]}
                      />
                      <Input
                          as='textarea'
                          nome='alteracoesGastroIntestinais.observacoes'
                          titulo='Observações'
                          classname='pergunta-container'
                          classNameTitulo='texto-pergunta'
                      />
                    </Secao>
                    <Secao chave='sintomasUrinarios'>
                      <OpcoesTriagem
                          titulo="Está sem urinar há mais de 8h?"
                          nome="sintomasUrinarios.semUrinarHaMaisDeOitoHoras"
                          opcoes={[{nome: 'Sim', codigo: true}, {nome: 'Não', codigo: false}]}
                          selecaoUnica={true}
                      />
                      <OpcoesTriagem
                          titulo="Há dor abdominal, no flanco ou abaixo do umbigo?"
                          nome="sintomasUrinarios.presencaDeDorAbdominal"
                          opcoes={[{nome: 'Sim', codigo: true}, {nome: 'Não', codigo: false}]}
                          aoResponder={aoResponderPerguntaPresencaDeDorAbdominalSintomasUrinarios}
                          selecaoUnica={true}
                      />
                      <EscalaTriagem
                          nome="sintomasUrinarios.dorAbdominal"
                          desabilitado={!values.sintomasUrinarios.presencaDeDorAbdominal}
                          escalas={[
                            { codigo: 'um', nome: 1 },
                            { codigo: 'dois', nome: 2 },
                            { codigo: 'tres', nome: 3 },
                            { codigo: 'quatro', nome: 4 },
                            { codigo: 'cinco', nome: 5 },
                            { codigo: 'seis', nome: 6 },
                            { codigo: 'sete', nome: 7 },
                            { codigo: 'oito', nome: 8 },
                            { codigo: 'nove', nome: 9 },
                            { codigo: 'dez', nome: 10 }
                          ]}
                      />
                      <MultiplaSelecaoTriagem
                          className="pergunta"
                          nome="sintomasUrinarios.situacoesAssociadas"
                          opcoes={situacoesAssociadasASintomasUrinarios}
                          pergunta="Teve alguma das seguintes situações associadas?"
                      />
                      <Input
                          as='textarea'
                          nome='sintomasUrinarios.observacoes'
                          titulo='Observações'
                          classname='pergunta-container'
                          classNameTitulo='texto-pergunta'
                      />
                    </Secao>
                    <Secao chave='dorMusculoesqueletica'>
                      <OpcoesTriagem
                          titulo="Informar a intensidade da dor?"
                          nome="dorMusculoesqueletica.presencaDeDor"
                          opcoes={[{nome: 'Sim', codigo: true}, {nome: 'Não', codigo: false}]}
                          aoResponder={aoResponderPerguntaPresencaDeDorMuscoloesqueletica}
                          selecaoUnica={true}
                      />
                      <EscalaTriagem
                          nome="dorMusculoesqueletica.intensidade"
                          desabilitado={!values.dorMusculoesqueletica.presencaDeDor}
                          escalas={[
                            { codigo: 'um', nome: 1 },
                            { codigo: 'dois', nome: 2 },
                            { codigo: 'tres', nome: 3 },
                            { codigo: 'quatro', nome: 4 },
                            { codigo: 'cinco', nome: 5 },
                            { codigo: 'seis', nome: 6 },
                            { codigo: 'sete', nome: 7 },
                            { codigo: 'oito', nome: 8 },
                            { codigo: 'nove', nome: 9 },
                            { codigo: 'dez', nome: 10 }
                          ]}
                      />
                      <OpcoesTriagem
                          titulo="A dor é incapacitante (impede a pessoa de fazer até pequenos movimentos)?"
                          nome="dorMusculoesqueletica.incapacitante"
                          opcoes={[{nome: 'Sim', codigo: true}, {nome: 'Não', codigo: false}]}
                          selecaoUnica={true}
                          desabilitado={!values.dorMusculoesqueletica.intensidade}
                      />
                      <OpcoesTriagem
                          titulo="Iniciou há menos de 24h?"
                          nome="dorMusculoesqueletica.iniciouHaMenosDeVinteEQuatroHoras"
                          opcoes={[{nome: 'Sim', codigo: true}, {nome: 'Não', codigo: false}]}
                          selecaoUnica={true}
                      />
                      <MultiplaSelecaoTriagem
                          className="pergunta"
                          nome="dorMusculoesqueletica.sintomasAssociados"
                          opcoes={sintomasAssociadasAoLocalDaDorMusculoesqueletica}
                          pergunta="Sintomas associados ao local da dor?"
                      />
                      <Input
                          as='textarea'
                          nome='dorMusculoesqueletica.observacoes'
                          titulo='Observações'
                          classname='pergunta-container'
                          classNameTitulo='texto-pergunta'
                      />
                    </Secao>
                    <Secao chave='hemorragia'>
                      <OpcoesTriagem
                          titulo="Há sinais de hemorragia? (Se sim, descreva com mais detalhes no campo Observações)"
                          nome="hemorragia.sinaisDeHemorragia"
                          opcoes={[{nome: 'Sim', codigo: true}, {nome: 'Não', codigo: false}]}
                          selecaoUnica={true}
                          aoResponder={aoResponderPerguntaSinaisDeHemorragia}
                      />
                      <OpcoesTriagem
                          titulo="O que acontece com a hemorragia ao pressionar com as mãos?"
                          nome="hemorragia.respostaAPressao"
                          opcoes={respostasAPressaoNoLocalDaHemorragia}
                          desabilitado={!values.hemorragia.sinaisDeHemorragia}
                          selecaoUnica={true}
                      />
                      <OpcoesTriagem
                          titulo="O paciente está usando anticoagulantes?"
                          nome="hemorragia.usaAnticoagulantes"
                          opcoes={[{nome: 'Sim', codigo: true}, {nome: 'Não', codigo: false}]}
                          selecaoUnica={true}
                      />
                      <Input
                          as='textarea'
                          nome='hemorragia.observacoes'
                          titulo='Observações'
                          classname='pergunta-container'
                          classNameTitulo='texto-pergunta'
                      />
                    </Secao>
                  </Secoes>
                </div>
                <div className='acoes-triagem'>
                  <button type='button' className='button --light' onClick={cancelar}>Sair</button>
                  <button type='submit' className='button --primary'>Gravar</button>
                </div>
              </div>
              )}
            </Formulario>
        )}
      </Spinner>
    </>
  )
}