import {
  LISTOU_TESTE_DE_TIMEOUT
} from '../acoes/tipos'

export default (state = [], action) => {
  switch (action.type) {
    case LISTOU_TESTE_DE_TIMEOUT: {
      return action.teste
    }

    default: {
      return state
    }
  }
}